<template>
  <div class="our-advantage">
    <h2>我们的优势</h2>
    <ul>
      <li
        v-for="item in ourAdvantageList"
        :key="item.id"
        :class="item.iconClass"
        class="our-advantage-item"
      >
        <div class="icon" />
        <h3>{{ item.title }}</h3>
        <p>{{ item.text }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
// 我们的优势
export default {
  name: 'OurAdvantage',
  components: {},
  props: {},
  data () {
    return {
      ourAdvantageList: [
        {
          id: 1,
          iconClass: 'strength-safety',
          title: '安全认证',
          text: '绿色安全支付',
        },
        {
          id: 2,
          iconClass: 'strength-speed',
          title: '极速',
          text: '高效、快捷',
        },
        {
          id: 3,
          iconClass: 'strength-major',
          title: '专业',
          text: '二十年经验团队',
        },
        {
          id: 4,
          iconClass: 'strength-reliable',
          title: '可靠',
          text: '千万级用户选择',
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.our-advantage {
  h2 {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #191140;
    margin-bottom: 52px;
    margin-top: 102px;
  }
  ul {
    display: flex;
    justify-content: center;
    margin: auto;
    flex-wrap: wrap;
    .our-advantage-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 285px;
      height: 280px;
      background: #ffffff;
      box-shadow: 0px 5px 12px 3px rgba(0, 0, 0, 0.01);
      border-radius: 8px;
      border: 1px solid rgba(164, 168, 184, 0.3);
      margin-left: 20px;
      &:first-of-type {
        margin-left: 0;
      }
      .icon {
        width: 91px;
        height: 91px;
        background-size: 100%;
      }
      h3 {
        font-size: 24px;
        font-weight: 600;
        color: #222222;
        margin-top: 32px;
        margin-bottom: 8px;
      }
      p {
        color: #999999;
      }
      &:hover {
        box-shadow: 0px 16px 22px -4px rgba(134, 134, 134, 0.3);
      }
      &.strength-safety {
        .icon {
          background: url("../_images/strength-safety.png") no-repeat;
        }
      }
      &.strength-speed {
        .icon {
          background: url("../_images/strength-speed.png") no-repeat;
        }
      }
      &.strength-major {
        .icon {
          background: url("../_images/strength-major.png") no-repeat;
        }
      }
      &.strength-reliable {
        .icon {
          background: url("../_images/strength-reliable.png") no-repeat;
        }
      }
      &.our-advantage-item .icon{
        background-size: 100%;
      }
    }
  }
}
</style>
