



























































import { defineComponent, reactive, computed } from '@vue/composition-api'
import { useReflectiveInjector } from '@zhfe/vue2-di-plugin'
import { ConfigService } from '@/service/config.service'
import { PopularProductsService } from '@/views/home/_components/popular-products.service'

export default defineComponent({
  name: 'PopularProducts',
  setup () {
    const positionMap = {
      1: 'pdf',
      2: 'picture',
      3: 'computer',
      4: 'compress',
      5: 'file',
      6: 'bg'
    }
    const injector = useReflectiveInjector([PopularProductsService])
    const service = injector.get(PopularProductsService)
    const config = injector.get(ConfigService)
    const viewModel = reactive({
      productId: 1,
      productList: [
        { id: 1, iconClass: 'pdf-logo', productName: '可牛PDF' },
        { id: 2, iconClass: 'picture-logo', productName: '可牛看图' },
        { id: 3, iconClass: 'computer-logo', productName: '可牛清理大师' },
        { id: 4, iconClass: 'compress-logo', productName: '可牛压缩' },
        { id: 5, iconClass: 'file-logo', productName: '可牛文件管理' },
        { id: 6, iconClass: 'bg-logo', productName: '可牛办公模板' },
      ],
      featuresMap: config.featuresMap,
      urlMap: {
        ...config.downloadUrl
      }
    })

    const position = computed(() => positionMap[viewModel.productId])
    const featuresList = computed(() => {
      const name = positionMap[viewModel.productId]
      return viewModel.featuresMap[name]
    })

    const url = computed(() => {
      const name = positionMap[viewModel.productId]
      return viewModel.urlMap[name]
    })

    /** 是否为可牛办公模板 */
    const isOfficeTemplate = computed(() => viewModel.productId === 6)

    function clickProduct (id:number) {
      viewModel.productId = id
    }

    function clickDownload () {
      const downloadId = viewModel.productId

      if (!isOfficeTemplate.value) {
        config.downloadFile(url.value)
      } else {
        window.open('https://o.keniu.com')
      }

      service.report({
        act: 2,
        click_hot: downloadId,
        download: isOfficeTemplate.value ? 0 : downloadId
      })
    }

    return {
      viewModel,
      url,
      position,
      featuresList,
      isOfficeTemplate,
      clickProduct,
      clickDownload
    }
  }
})

