<template>
  <div class="user-evaluation">
    <h2>用户评价</h2>
    <div
      class="ranklist-carousel"
      @mouseenter="handleStopInterval"
      @mouseleave="handleStartInterval"
    >
      <template v-if="list.length">
        <span
          class="prev_btn"
          @click="handlePrev"
        />
        <span
          class="next_btn"
          @click="handleNext"
        />
      </template>
      <template v-for="(item, index) in list">
        <div
          v-if="index < len"
          :key="index"
          class="item"
          :style="parsePosition(index, cur)"
          :class="{ active: cur === index }"
        >
          <div class="card-wrapper">
            <div
              v-show="cur === index"
              class="active-icon"
            />
            <p class="reviews">
              {{ item.reviews }}
            </p>
            <div class="userinfo">
              <div>
                <p class="nick-name">
                  {{ item.nickName }}
                </p>
                <span class="profession">职业：{{ item.profession }}</span>
              </div>
              <div class="avatar">
                <img
                  :src="require(`../_images/${item.icon}.png`)"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserEvaluation',
  components: {},
  props: {
    list: {
      type: Array,
      default: () => [
        {
          id: 1,
          nickName: '大毛',
          profession: '学生',
          reviews:
            '日常学习中需要对资料进行解压缩操作，使用可牛压缩效率高，体积小，支持解压缩格式繁多，对日常学习效率有明显提高。',
          icon: 'active-a',
        },
        {
          id: 2,
          nickName: 'Cindy',
          profession: '会计师',
          reviews:
            '我一开始使用可牛PDF是用来转换文件格式的，因为做好的Word文件发给别人以后格式老是出错，后来发现其他功能也很好用，就买了会员 。',
          icon: 'active-b',
        },
        {
          id: 3,
          nickName: '伊娜',
          profession: '摄影师',
          reviews:
            '日常工作是婚礼摄影，每次拍摄完成会有大量照片需要提供给客户进行挑选，可牛看图用起来十分轻巧，日常看图同时又可以进行图片编辑操作，用起来很便捷。',
          icon: 'active-c',
        },
      ],
    },

    interval: {
      type: Number,
      default: 3500,
    },
  },
  data () {
    return {
      autoplay: true,
      timer: null,
      len: 3,
      cur: 0,
    }
  },

  watch: {
    list () {
      this.cur = 0
      this.handleStartInterval()
    },
    autoplay (val) {
      if (val) {
        this.handleStartInterval()
        return
      }

      this.handleStopInterval()
    },
  },
  mounted () {
    if (this.autoplay) {
      this.handleStartInterval()
    }
  },
  methods: {
    parsePosition (index, cur) {
      const leftData = {
        0: {
          0: 354,
          1: 0,
          2: 747,
        },
        1: {
          0: 747,
          1: 354,
          2: 0,
        },
        2: {
          0: 0,
          1: 747,
          2: 354,
        },
      }

      let top = 10
      const left = leftData[cur][index]
      let widthHeightStyle = ''

      if (index === cur) {
        top = 0
        widthHeightStyle = ' width: 460px; height: 270px;z-index: 2;'
      }

      return `transform: translateX(${left}px) translateY(${top}px); ${widthHeightStyle}`
    },
    handleStartInterval () {
      clearTimeout(this.timer)
      this.autoplay = true
      this.timer = setTimeout(() => {
        requestAnimationFrame(this.handleInterval)
      }, this.interval)
    },
    handleStopInterval () {
      this.autoplay = false
    },
    handleInterval () {
      if (!this.autoplay) {
        return
      }

      this.handleNext()

      this.timer = setTimeout(() => {
        requestAnimationFrame(this.handleInterval)
      }, this.interval)
    },
    handleSwitch ($event) {
      const index = Number($event.currentTarget.dataset.index)
      this.cur = index
    },
    handleNext () {
      let index = this.cur - 1
      if (index < 0) {
        index = this.len - 1
      }

      this.cur = index
    },
    handlePrev () {
      let index = this.cur + 1
      if (index >= this.len) {
        index = 0
      }

      this.cur = index
    },
  },
}
</script>

<style scoped lang="scss">
.user-evaluation {
  h2 {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #191140;
    margin-bottom: 52px;
    margin-top: 102px;
  }

  .ranklist-carousel {
    height: 272px;
    user-select: none;
    width: 1208px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 50px;
  }
  .prev_btn,
  .next_btn {
    display: block;
    height: 50px;
    width: 50px;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    border-radius: 50%;
    background: #dee0e3 url("../_images/carousel-icon.png") no-repeat 20px center;
    background-size: 30%;
    cursor: pointer;
    &:hover {
      background: #b8babc url("../_images/carousel-icon.png") no-repeat 20px center;
      background-size: 30%;
    }
  }
  .prev_btn {
    right: -16px;
  }
  .next_btn {
    transform: rotate(180deg);
    left: -16px;
  }
  .item {
    z-index: 1;
    position: absolute;
    width: 460px;
    height: 240px;
    background: #ffffff bottom;
    box-shadow: 0px 3px 15px 3px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    border: 1px solid #ededed;
    transition: 0.4s;
    opacity: 0.3;
    .card-wrapper {
      padding: 20px 27px;

      .active-icon {
        display: block;
        background: url("../_images/quotes.png") no-repeat;
        background-size: 100% 100%;
        width: 43px;
        height: 42px;
        margin-bottom: 16px;
      }
      .reviews {
        font-size: 16px;
        font-weight: 400;
        color: #0b1d30;
        line-height: 33px;
      }
      .userinfo {
        display: flex;
        text-align: end;
        justify-content: flex-end;
        height: 60px;
        .nick-name {
          font-size: 18px;
          font-weight: 600;
          color: #0b1d30;
          line-height: 32px;
        }
        .profession {
          font-size: 14px;
          font-weight: 400;
          color: #5e627b;
        }
        .avatar {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          margin-left: 11px;
          img {
            height: 60px;
          }
        }
      }
    }

    &.active {
      opacity: 1;
      background: #fff url("../_images/card-active-bg.png") no-repeat bottom;
      background-size: 100%;
    }
  }
}
</style>
