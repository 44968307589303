<template>
  <div class="use-scene">
    <h2>应用场景</h2>
    <ul>
      <li
        v-for="item in useSceneList"
        :key="item.id"
        :class="item.className"
        class="use-scene-item"
      >
        <div class="icon" />
        <div>
          <h3>{{ item.title }}</h3>
          <p>{{ item.text }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// 应用场景
export default {
  name: 'UseScene',
  data () {
    return {
      useSceneList: [
        {
          id: 1,
          className: 'government-affairs',
          title: '政务办公',
          text: '专业准确的文档处理软件，助力政务工作稳定展开',
        },
        {
          id: 2,
          className: 'financial-accounting',
          title: '财务会计',
          text: '多种PDF编辑处理工具，提升文件处理速度',
        },
        {
          id: 3,
          className: 'enterprise',
          title: '企业办公',
          text: '各类办公工具，解决办公难题，提升办公效率',
        },
        {
          id: 4,
          className: 'publishing',
          title: '编辑出版',
          text: '功能全面的文档编辑工具，提高文档处理效率，带来全新编辑体验',
        },
        {
          id: 5,
          className: 'education',
          title: '学习教育',
          text: '满足科研及教学的不同需求，专业工具成就更好未来',
        },
        {
          id: 6,
          className: 'more-scenes',
          title: '更多场景',
          text: '更多场景接入中...',
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.use-scene {
  overflow: hidden;
  height: 646px;
  background: url("../_images/use-scene-bg.jpg") no-repeat center;
  h2 {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #ffffff;
    margin-top: 60px;
    margin-bottom: 50px;
  }
  ul {
    display: flex;
    width: 1198px;
    justify-content: center;
    margin: auto;
    flex-wrap: wrap;
    .use-scene-item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      margin-top: 20px;
      flex-shrink: 0;
      width: 386px;
      height: 180px;
      background: #ffffff;
      border-radius: 10px;
      transition: all 300ms;

      &:nth-child(-n + 3) {
        margin-top: 0;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }

      .icon {
        background: red;
        width: 120px;
        height: 120px;
        flex-shrink: 0;
        margin-left: 40px;
        margin-right: 8px;
      }
      h3 {
        font-size: 24px;
        font-weight: 500;
        color: #0b1d30;
      }
      p {
        display: none;
        font-size: 14px;
        font-weight: 400;
        width: 180px;
        margin-top: 16px;
        line-height: 20px;
      }
      &:hover {
        h3,
        p {
          display: block;
          color: #fff;
        }
      }
      &.government-affairs {
        .icon {
          background: url("../_images/government-affairs-icon.png") no-repeat;
        }
        &:hover {
          .icon {
            background: url("../_images/government-affairs-icon-hover.png") no-repeat;
          }
          background: url("../_images/government-affairs-bg.png") no-repeat;
        }
      }
      &.financial-accounting {
        .icon {
          background: url("../_images/financial-accounting-icon.png") no-repeat;
        }
        &:hover {
          .icon {
            background: url("../_images/financial-accounting-icon-hover.png") no-repeat;
          }
          background: url("../_images/financial-accounting-bg.png") no-repeat;
        }
      }
      &.enterprise {
        .icon {
          background: url("../_images/enterprise-icon.png") no-repeat;
        }
        &:hover {
          .icon {
            background: url("../_images/enterprise-icon-hover.png") no-repeat;
          }
          background: url("../_images/enterprise-bg.png") no-repeat;
        }
      }
      &.publishing {
        .icon {
          background: url("../_images/publishing-icon.png") no-repeat;
        }
        &:hover {
          .icon {
            background: url("../_images/publishing-icon-hover.png") no-repeat;
          }
          background: url("../_images/publishing-bg.png") no-repeat;
        }
      }
      &.education {
        .icon {
          background: url("../_images/education-icon.png") no-repeat;
        }
        &:hover {
          background: url("../_images/education-bg.png") no-repeat;
          .icon {
            background: url("../_images/education-icon-hover.png") no-repeat;
          }
        }
      }
      &.more-scenes {
        .icon {
          background: url("../_images/more-scenes-icon.png") no-repeat;
        }
        &:hover {
          .icon {
            background: url("../_images/more-scenes-icon-hover.png") no-repeat;
          }
          background: url("../_images/more-scenes-bg.png") no-repeat;
        }
      }

      &.use-scene-item {
        .icon {
          background-size: 100%;
        }
        &:hover {
          background-size: 100%;
          .icon {
            background-size: 100%;
          }
        }
      }
    }
  }
}
</style>
