import { Injectable } from '@zhfe/vue2-di-plugin'
import { ReportService } from '@/service/report.service'

@Injectable()
export class PopularProductsService {
  constructor (
    private reporter: ReportService
  ) {
  }

  report = this.reporter.webReport({
    act: 0,
    click_tab: 0,
    click_banner: 0,
    click_hot: 0,
    download: 0,
    csource: 1,
    click_product: 0
  }, this.reporter.table.infockeniu_website_act)
}
