

















import Carousel from '@/components/carousel/carousel.vue'
import PopularProducts from './_components/popular-products.vue'
import UseScene from './_components/use-scene.vue'
import OurAdvantage from './_components/our-advantage.vue'
import UserEvaluation from './_components/user-evaluation.vue'

export default {
  name: 'Home',
  components: {
    Carousel,
    PopularProducts,
    UseScene,
    OurAdvantage,
    UserEvaluation,
  },
}
